//Modules
import React from "react";

//Components
import { Page } from "../../Components/";
import { ImageColumn } from "./Components";

//Styles
import "./inc/css/Style.css";

//Images
import Image1 from "./inc/img/Image1.jp2";
import Image2 from "./inc/img/Image2.jp2";
import Image3 from "./inc/img/Image3.jp2";
import Image4 from "./inc/img/Image4.jp2";
import Image5 from "./inc/img/Image5.jp2";
import Image6 from "./inc/img/Image6.jp2";
import Image7 from "./inc/img/Image7.jp2";
import Image8 from "./inc/img/Image8.jp2";
import Image9 from "./inc/img/Image9.jp2";
import Image10 from "./inc/img/Image10.jp2";
import Image11 from "./inc/img/Image11.jp2";
import Image12 from "./inc/img/Image12.jp2";
import Image13 from "./inc/img/Image13.jp2";
import Image14 from "./inc/img/Image15.jp2";

const ColumnOne = [
	{ Source: Image1, Title: "Antelope Canyon", Photographer: "Paul IJsendoorn" },
	{ Source: Image2, Title: "Antelope Canyon, Arizona", Photographer: "Francesco Ungaro" },
	{ Source: Image3, Title: "Brown Canyon", Photographer: "Pixabay" },
	{ Source: Image10, Title: "Photo 1", Photographer: "Emily Law" }
];

const ColumnTwo = [
	{ Source: Image4, Title: "Antelope Canyon", Photographer: "Andy Hamill" },
	{ Source: Image7, Title: "Antelope, Arizona", Photographer: "Belle Co" },
	{ Source: Image11, Title: "Photo 2", Photographer: "Emily Law" },
	{ Source: Image12, Title: "Photo 3", Photographer: "Emily Law" }
];

const ColumnThree = [
	{ Source: Image6, Title: "Scenic photo of an antelope canyon", Photographer: "Ray Bilcliff" },
	{ Source: Image5, Title: "Canyon", Photographer: "Andy Hamill" },
	{ Source: Image14, Title: "Photo 6", Photographer: "Emily Law" }
];

const ColumnFour = [
	{ Source: Image8, Title: "Low-angle Photography Of Antelope Canyon", Photographer: "Pixabay" },
	{ Source: Image9, Title: "Brown Rock Formation", Photographer: "Pixabay" },
	{ Source: Image13, Title: "Photo 4", Photographer: "Emily Law" }
];

/**
 * The website's gallery page.
 */
export default class Gallery extends React.Component{
	/**
	 * Render the component
	 */
	public render(){
		return(
			<Page HeaderCanBeTransparent={false}>
				<div id="Gallery">
					<ImageColumn Images={ColumnOne} />
					<ImageColumn Images={ColumnTwo} />
					<ImageColumn Images={ColumnThree} />
					<ImageColumn Images={ColumnFour} />
				</div>
			</Page>
		);
	}
}