//Modules
import React from "react";

//Types
import { IParallaxImageProps, IParallaxImageState } from "./Types";

//Styles
import "./inc/css/Style.css";

/**
 * Parallax Image component, gives a parallax effect to the rendered image
 */
export default class ParallaxImage extends React.Component<IParallaxImageProps, IParallaxImageState>{
	/**
	 * The current state of the component
	 */
	public state: IParallaxImageState = {
		Element: undefined,
		ImageOffset: 0
	};

	/**
	 * Runs immediately after the component mounts, initializes the onScroll event
	 */
	public componentDidMount(){
		window.addEventListener("scroll", this.OnScroll);

		//Start with the correct element
		window.addEventListener("load", this.OnScroll);
	}

	/**
	 * Runs immediately before the component unmounts, removes the onScroll event
	 */
	public componentWillUnmount(){
		window.removeEventListener("scroll", this.OnScroll);
	}

	/**
	 * Render the component
	 */
	public render(){
		const ImageStyle: React.CSSProperties = {
			transform: `translate(0, ${this.state.ImageOffset}px)`
		};

		const RefFunction = (El: HTMLDivElement | null) => this.state.Element = El !== null ? El : undefined;

		return (
			<div id={this.props.ID} className={"ParallaxImage " + this.props.className} style={this.props.style} ref={RefFunction}>
				<img style={ImageStyle} className="bg-image" src={this.props.src} alt={this.props.alt} />
				{this.props.children}
			</div>
		);
	}

	/**
	 * To be called when the page is scrolled, updates the offset for the parallax image
	 */
	private readonly OnScroll = () => {
		//Dont apply parallax effect on mobile
		const IsMobile = window.innerWidth <= 1024;

		const Offset = ((this.state.Element || document.body).offsetTop - window.scrollY) / this.props.ScrollSpeed;

		this.setState({
			ImageOffset: IsMobile ? 0 : Offset
		});
	}
}