//Modules
import React from "react";

//Components
import { Header, LoadingAnimation } from "./Components";

//Types
import { IPageProps } from "./Types";

//Styles
import "./inc/css/Style.css";

/**
 * Default page template, renders the header and the MainContent container
 */
export default class Page extends React.Component<IPageProps>{
	/**
	 * Render the component
	 */
	public render(){
		return (
			<div id="Page">
				<LoadingAnimation />
				<div id="MainContent">
					{this.props.children}
				</div>
				<Header CanBeTransparent={this.props.HeaderCanBeTransparent} />
			</div>
		);
	}
}