//Modules
import React from "react";

//Types
import { IDownArrowProps, IDownArrowState } from "./Types";

//Styles
import "./inc/css/Style.css";

/**
 * Down arrow button, used to scroll to a specified element
 */
export default class DownArrow extends React.Component<IDownArrowProps, IDownArrowState>{
	/**
	 * The current state of the down arrow component
	 */
	public state: IDownArrowState = {
		IsAtTop: true
	};

	/**
	 * Runs immediately after the component mounts, initializes the onScroll event
	 */
	public componentDidMount(){
		this.OnScroll();

		window.addEventListener("scroll", this.OnScroll);
	}

	/**
	 * Runs immediately before the component unmounts, removes the onScroll event
	 */
	public componentWillUnmount(){
		window.removeEventListener("scroll", this.OnScroll);
	}
	/**
	 * Render the component
	 */
	public render(){
		const DownArrowImageProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> = {
			className: "DownArrow",
			alt: "Down Arrow",
			style: {
				right: this.state.IsAtTop ? "48vw" : "2vw"
			},
			onClick: this.OnClick.bind(this)
		};

		return (
			<i {...DownArrowImageProps} />
		);
	}

	/**
	 * Map the query selectors passed in the props to their offset values
	 */
	private CalculateDestinationOffsets(){
		const DestinationOffsets: number[] = [];

		this.props.Destinations.forEach(Destination => {
			const Element = document.querySelector(Destination);

			if(Element !== null){
				DestinationOffsets.push((Element as HTMLElement).getBoundingClientRect().top + window.scrollY);
			}
		});

		return DestinationOffsets;
	}

	/**
	 * Scroll to the specified destination
	 */
	private OnClick(){
		const DestinationOffsets = this.CalculateDestinationOffsets();

		window.scroll({
			left: 0,
			top: DestinationOffsets.find(Destination => Destination > window.scrollY) || window.scrollY,
			behavior: "smooth"
		});
	}

	/**
	 * Update the state depending on whether the window is scrolled all the way up or not
	 */
	private readonly OnScroll = () => {
		const IsAtTop = window.scrollY === 0;

		if(this.state.IsAtTop !== IsAtTop){
			this.setState({ IsAtTop });
		}
	}
}