//Modules
import React from "react";

//Types
import { IMobileHeaderState } from "./Types";
import { Link } from "react-router-dom";

//Styles
import "./inc/css/Style.css";

/**
 * Mobile header, appears when the screen width is less than or equal to 1024px
 */
export default class MobileHeader extends React.Component<{}, IMobileHeaderState>{
	/**
	 * The current state of the component
	 */
	public state: IMobileHeaderState = {
		DisplayMenu: false
	};

	/**
	 * Render the component
	 */
	public render(){
		const HamburgerClassName = this.state.DisplayMenu ? "is-active" : "";

		return (
			<div>
				<span id="hamburger" onClick={this.ToggleMenu} className={HamburgerClassName}>
					<span id="hamburger-box">
						<span id="hamburger-inner" />
					</span>
				</span>
				<div id="mobileNav" style={{ top: this.state.DisplayMenu ? "0" : "-150vh" }}>
					<Link to="/"><span>Home</span></Link>
					<Link to="/Gallery"><span>Gallery</span></Link>
					<Link to="/About"><span>About</span></Link>
				</div>
			</div>
		);
	}

	/**
	 * Either show or hide the menu, depending on its current state
	 */
	private readonly ToggleMenu = () => {
		this.setState({ DisplayMenu: !this.state.DisplayMenu });
	}
}