//Modules
import React from "react";

//Components
import { DesktopHeader, MobileHeader } from "./Components";

//Types
import { IHeaderProperties, IHeaderState } from "./Types";

/**
 * The header component, present on every page
 */
export default class Header extends React.Component<IHeaderProperties, IHeaderState>{
	/**
	 * The current state of the component
	 */
	public state: IHeaderState = {
		IsAtTop: true
	};

	/**
	 * Runs immediately after the component mounts, initializes the onScroll event
	 */
	public componentDidMount(){
		this.OnScroll();

		window.addEventListener("scroll", this.OnScroll);
	}

	/**
	 * Runs immediately before the component unmounts, removes the onScroll event
	 */
	public componentWillUnmount(){
		window.removeEventListener("scroll", this.OnScroll);
	}

	/**
	 * Render the component
	 */
	public render(){
		const DesktopHeaderClass = this.state.IsAtTop ? "LargeHeader" : "SmallHeader";
		const DesktopHeaderTransparency = this.props.CanBeTransparent && this.state.IsAtTop;

		return window.innerWidth > 1024 ?
			(<DesktopHeader className={DesktopHeaderClass} Transparent={DesktopHeaderTransparency} />) :
			(<MobileHeader />);
	}

	/**
	 * Called when the page is scrolled, updates the state of the header depending on its distance from top of the page
	 */
	private readonly OnScroll = () => {
		const IsAtTop = window.scrollY === 0;

		if(this.state.IsAtTop !== IsAtTop){
			this.setState({ IsAtTop });
		}
	}
}