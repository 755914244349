//Modules
import React from "react";

//Components
import { Page, ParallaxImage } from "../../Components/";

//Styles
import "./inc/css/Style.css";

//Images
import Image from "./inc/img/Image.jp2";

/**
 * The website's home page.
 */
export default class About extends React.Component{
	/**
	 * Render the component
	 */
	public render(){
		return(
			<Page HeaderCanBeTransparent={false}>
				<ParallaxImage src={Image} ScrollSpeed={-2} className="AboutImage" alt="Background Image">
					<h1>About</h1>
				</ParallaxImage>
				<p>Approximately 7km from Page, Arizona, Lower Antelope Canyon is a slot canyon with a length of approximately 1335 feet (407m). Colloquailly known as 'The Corkscrew' the canyon is in some places less than one meter wide, however travelling into the canyon reveals some of the most amazing naturally occuring rock formations some coming eerily close to familiar objects such as human faces, created by millenia of flash flooding which is common in the area during certain seasons of the year. The canyon has only been open to the public since 1997 when it was designated as a Navajo Tribal Park by the Navajo tribe.</p>
				<ParallaxImage src={Image} ScrollSpeed={-2} className="AboutImage" alt="Background Image">
					<h1>Flooding</h1>
				</ParallaxImage>
				<p>During monsoon season flooding can be quite common and are very dangerous in the narrow corridors of Antelope Canyon, meaning that the staff of Antelope Canyon must be prepared to clear the canyon of any people at a moments notice.<br /><br />Despite the greatly improved safety procedures and infrastructure, such as ladders bolted in place, and an alarm stationed near the entrance, there is still a risk of being caught in a flood and trapped, or even killed which was the case on August 12, 1997 in which eleven tourists were killed in a flash flood in Lower Antelope Canyon.<br /><br />A flood on the 30th of October 2006 lasted for 36 hours and caused authorities to close Lower Antelope Canyon for an entire five months.</p>
				<ParallaxImage src={Image} ScrollSpeed={-2} className="AboutImage" alt="Background Image">
					<h1>Visiting</h1>
				</ParallaxImage>
				<p>Partially due to the risk of flooding and also partially due to it’s status as a Navajo Tribal Park, it is only possible to visit the canyon through a prebooked guided tour with any of the several tour companies which are operating at the canyon. While the canyon is open for the entire year it is best to visit during the summer months when the lighting is best.</p>
			</Page>
		);
	}
}