//Modules
import React from "react";

//Types
import { ILoadingAnimationState } from "./Types";

//Styles
import "./inc/css/Style.css";

/**
 * Displays a loading animation until all images are loaded
 */
export default class LoadingAnimation extends React.Component<{}, ILoadingAnimationState>{
	/**
	 * The state of the component
	 */
	public state: ILoadingAnimationState = {
		Loading: false
	};

	/**
	 * How many times the component has been rendered, used for deciding how long transitions take
	 */
	private RenderCount = 0;

	/**
	 * The total amount of images to be loaded
	 */
	private ImageCount = 0;

	/**
	 * The total amount of images that have been loaded so far
	 */
	private ImagesLoaded = 0;

	/**
	 * Runs when the component is first mounted, initializes the loading animation depending on how many images are unloaded
	 */
	public componentDidMount(){
		const Images = Array.from(document.getElementsByTagName("img")).filter(Img => !Img.complete);

		//Dont show the animation if all images are already cached
		if(Images.length === 0){
			return;
		}

		this.ImageCount = Images.length;

		this.setState({ Loading: true });

		for(const Image of Images){
			Image.addEventListener("load", this.HandleImageLoad);

			//Treat errors as images loading else the animation would never disappear
			Image.addEventListener("error", this.HandleImageLoad);
		}
	}

	/**
	 * Render the component
	 */
	public render(){
		const LoadingAnimationStyle: React.CSSProperties = {
			opacity: this.state.Loading ? 1 : 0,
			visibility: this.state.Loading ? "visible" : "hidden" as "visible" | "hidden",
			transition: this.RenderCount++ === 1 ? "none" : ""
		};

		return(
			<div id="LoadingAnimation" style={LoadingAnimationStyle}>
				<div className="lds-ring">
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		);
	}

	/**
	 * To be run when an image is loaded, increments the image loaded count and if all are loaded hides the loading animation
	 */
	private readonly HandleImageLoad = () => {
		this.ImagesLoaded++;

		if(this.ImagesLoaded >= this.ImageCount){
			this.setState({ Loading: false });
		}
	}
}
