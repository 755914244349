//Modules
import React from "react";

//Components
import { Page, ParallaxImage } from "../../Components/";
import { DownArrow } from "./Components";

//Styles
import "./inc/css/Style.css";

//Images
import HeaderImage from "./inc/img/Header.jp2";
import MyStoryImage from "./inc/img/MyStory.jp2";

//Types
import { IParallaxImageProps } from "../../Components/ParallaxImage/Types";

/**
 * The website's home page.
 */
export default class Home extends React.Component{
	/**
	 * Render the component
	 */
	public render(){
		const FirstImageProps: IParallaxImageProps = {
			className: "FullPageParallax",
			src: HeaderImage,
			ScrollSpeed: -2,
			style: {
				marginTop: "-13vh"
			},
			alt: "Header Image"
		};

		const SecondImageProps: IParallaxImageProps = {
			className: "FullPageParallax",
			src: MyStoryImage,
			ScrollSpeed: -2,
			alt: "My Story Image",
			ID: "SecondParallaxImage"
		};

		return (
			<Page HeaderCanBeTransparent>
				<ParallaxImage {...FirstImageProps}>
					<h1>Lower Antelope Canyon</h1>
					<DownArrow Destinations={[ "#LowerAntelopeCanyon", "#SecondParallaxImage", "#MyStory" ]} />
				</ParallaxImage>
				<p id="LowerAntelopeCanyon">Lower Antelope Canyon, known by the native Navajo tribe as “Hazdistazí”, which directly translates to spiral rock arches, is a slot canyon formed by the erosion of Navajo Sandstone due to flash flooding in the Navajo desert, approximately 7km east of Page, Arizona, United States of America.</p>
				<ParallaxImage {...SecondImageProps}>
					<h1>My Story</h1>
				</ParallaxImage>
				<p id="MyStory">Over the New Zealand summer of 2016-2017 I was lucky enough to spend six weeks travelling in the south west of the United States of America. Starting in Los Angeles, California I travelled as far east as Roswell, New Mexico.<br /><br />During the later stage of the journey we found ourselves staying inside of Page, Arizona and we decided to spend part of our day doing a tour through Lower Antelope Canyon. During this tour I saw some of the most amazing and beautiful naturally occurring rock formations that I have ever witnessed. Carved into the rock face by the flash floods which frequently happen in the area were many amazing formations, including the one depicted in the image at the top of this page, in which an image that is eerily similar to a human neck and face can be seen in the right side of the image.</p>
			</Page>
		);
	}
}