//Modules
import React from "react";
import ReactDOM from "react-dom";

//Components
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home, Gallery, About } from "./Pages";

//Styles
import "./index.css";

ReactDOM.render((
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/Home" component={Home} />
			<Route path="/Gallery" component={Gallery} />
			<Route path="/About" component={About} />
		</Switch>
	</BrowserRouter>
), document.getElementById("root"));