//Modules
import React from "react";

//Components
import { Link } from "react-router-dom";

//Types
import { IDesktopHeaderProps } from "./Types";

//Styles
import "./inc/css/Style.css";

//Images
import Icon from "./inc/img/Icon.png";

/**
 * The desktop header, appears when the screen width is over 1024 pixels
 */
export default class DesktopHeader extends React.Component<IDesktopHeaderProps>{
	/**
	 * Render the component
	 */
	public render(){
		const Style: React.CSSProperties = {
			backgroundColor: this.props.Transparent ? "#00000000" : "white"
		};

		return (
			<header className={this.props.className} style={Style}>
				<Link to="/">
					<img alt="Logo Image" title="Lower Antelope Canyon" src={Icon} />
				</Link>
				<ul className="desktopHeader">
					<li id="HeaderItem">
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/Gallery">Gallery</Link>
					</li>
					<li>
						<Link to="/About">About</Link>
					</li>
				</ul>
			</header>
		);
	}
}