//Modules
import React from "react";

//Components
import { Image } from "./Components";

//Types
import { IImageColumnProps } from "./Types";

//Styles
import "./inc/css/Style.css";

/**
 * An column of images which when clicked open an image modal
 */
export default class ImageColumn extends React.Component<IImageColumnProps>{
	/**
	 * Render the component.
	 */
	public render(){
		return (
			<div className="ImageColumn">
				{this.RenderImages()}
			</div>
		);
	}

	/**
	 * Renders the images inside the column
	 */
	private RenderImages(){
		return this.props.Images.map(Img =>
			<Image key={Img.Title} Source={Img.Source} Title={Img.Title} Photographer={Img.Photographer} />
		);
	}
}