//Modules
import React from "react";

//Types
import { IImageProps, IImageState } from "./Types";

//Styles
import "./inc/css/Style.css";

//Images
import ExitButtonImage from "./inc/img/ExitButton.png";

/**
 * An image component
 */
export default class Image extends React.Component<IImageProps, IImageState>{
	/**
	 * The current state of the component
	 */
	public state: IImageState = {
		RenderModal: false
	};

	/**
	 * Render the component
	 */
	public render(){
		const ModalStyle: React.CSSProperties = {
			display: this.state.RenderModal ? "block" : "none"
		};

		const ImageTitle = `${this.props.Title} - ${this.props.Photographer}`;

		const ImageProperties: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> = {
			title: ImageTitle,
			alt: ImageTitle,
			src: this.props.Source,
			className: "ImageModal"
		};

		return (
			<div>
				<div style={ModalStyle} className="ImageModalContainer">
					<img {...ImageProperties} />
					<span className="Close" onClick={this.HideModal}>&times;</span>
					<p className="Caption">{ImageTitle}</p>
				</div>
				<img {...ImageProperties} className="Image" onClick={this.ShowModal} />
			</div>
		);
	}

	/**
	 * Shows the image modal
	 */
	private readonly ShowModal = () => {
		this.setState({ RenderModal: true });
	}

	/**
	 * Hides the image modal
	 */
	private readonly HideModal = () => {
		this.setState({ RenderModal: false });
	}
}